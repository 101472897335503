import { useNavigate } from "react-router";

export default function MobileNav() {
  const navigate = useNavigate();
  const navEnum = {
    "home": () => navigate(`/home`),
    "projects": () => navigate(`/projects`),
    "contact": () => navigate(`/contact`),
    "timeline": () => navigate(`/timeline`)
  };

  function nav(route) {
    navEnum[route]();
  }

  return (
    <div className="mobile-nav" id="mobile-nav">
      <button className="mobile-nav-btn" onClick={() => nav("home")}>Home</button>
      <button className="mobile-nav-btn" onClick={() => nav("timeline")}>Timeline</button>
      <button className="mobile-nav-btn" onClick={() => nav("projects")}>Projects</button>
      <button className="mobile-nav-btn" onClick={() => nav("contact")}>Contact</button>
    </div>
  );
}