import { useContext, useState } from "react";
import { SiteContext } from "../SiteContent";
import RgbaColorPicker from "./RgbaColorPicker";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";

export default function GradientColorPicker({nameOfField, vert, imgCheck, imgName}){
    const [display, setDisplay] = useState(false)
    const {colors, handleFrontPageImages} = useContext(SiteContext);
    return (
        <div className="plus-container">
            {display ? (
                colors ? (
                    <div className={`color-picker ${vert ? "vertical-color" : ""}`}>
                        {colors[nameOfField].map((x, i) => (
                            <RgbaColorPicker 
                                key={i} 
                                initialColor={x} 
                                index={i} 
                                name={nameOfField} 
                                imgCheck={imgCheck}
                            />
                        ))}
                        {imgCheck ? (<div>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleFrontPageImages(imgName, e.target.files[0])}
                            />
                            </div>) : null
                        }
                        <button onClick={() => setDisplay((prev) => !prev)} className="minus-button"><FaMinusCircle size={'2rem'}/>
</button>
                    </div>
                    ) : (
                        <p>No projects available</p>
                    )
                ) : (
                    <button onClick={() => setDisplay((prev) => !prev)} className="plus-button"><FaPlusCircle size={'2rem'}/></button>
                )
            }
            </div>
    )
}