import React, {useEffect, useContext} from "react";
import { SiteContext } from '../../SiteContent'
import Navbar from '../Navbar';
import Footer from '../Footer';

export default function AdminContact() {
    const { getUser, colors, fetchData, setColors } = useContext(SiteContext);

    useEffect(() => {
        getUser();
    }, []);
    useEffect(function(){
        const check = JSON.parse(sessionStorage.getItem('site_colors'));
        if(check){setColors(check);}
        else{fetchData();}
    }, [])  
    return (
        <div>
            {colors ? (
                <>
                    <Navbar type={"admin"} />
                    <iframe className='candedly' width="100%" height="100%" frameborder="0" title="Calendly Scheduling Page" src="https://calendly.com/syd-curtis012?embed_type=Inline&amp;embed_domain=1"></iframe>
                    <Footer type={"admin"} />
                </>
            ) : null}
        </div>
    );
}
