import React from 'react';
import ReactDOM from 'react-dom/client';
import './Styles/css/main.css';
import Projects from './Components/User_Pages/Projects';
import Contact from './Components/User_Pages/Contact';
import Home from './Components/User_Pages/Home';
import Timeline from './Components/User_Pages/Timeline';
import {SiteContent} from './SiteContent';
import AdminContact from './Components/Admin/AdminContact';
import AdminHome from './Components/Admin/AdminHome';
import AdminProjects from './Components/Admin/AdminProjects';
import AdminLogin from './Components/Admin/AdminLogin';
import AdminTimeline from './Components/Admin/AdminTimeline';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <SiteContent>
        <Routes>
          <Route path="/home" Component={Home} />
          <Route path="/projects" Component={Projects} />
          <Route path="/timeline" Component={Timeline} />
          <Route path="/contact" Component={Contact} />
          <Route path="/admin" Component={AdminLogin} />
          <Route path="/admin/home" Component={AdminHome} />
          <Route path="/admin/projects" Component={AdminProjects} />
          <Route path="/admin/timeline" Component={AdminTimeline} />
          <Route path="/admin/contact" Component={AdminContact} />
          <Route path='*' element={<Navigate to='/home' />} />
        </Routes>
      </SiteContent>
    </BrowserRouter>
);