import { useNavigate } from "react-router";
import logo from "../Images/logo_1.png";
import {SiteContext} from "../SiteContent";
import { useContext } from "react";
import { Link } from "react-router-dom";

export default function Footer({type}) {
  const {colors} = useContext(SiteContext)
  const navigate = useNavigate();
  var pathing;
  if(type === "normal") pathing = ""
  else pathing = "admin/"
  const navEnum = {
    "home": () => navigate(`/${pathing}home`),
    "projects": () => navigate(`/${pathing}projects`),
    "contact": () => navigate(`/${pathing}contact`),
    "timeline": () => navigate(`/${pathing}timeline`)
  };

  function nav(route) {
    navEnum[route]();
  }
  return (
    <footer style={{ 
      backgroundColor: `${colors ? colors.first : ""}`,  
      borderTop: `0.5rem ${colors ? colors.second : ""} solid`
    }}>
      <img src={logo} alt="Portfolio Logo"/>
      <article className="footer-info-container">
        <section className="footer-section">
          <ul>
            <li style={{color: `${colors ? colors.seventh : ""}`}} className="footer-text" onClick={() => nav("home")}>Home</li>
            <li style={{color: `${colors ? colors.seventh : ""}`}} className="footer-text" onClick={() => nav("timeline")}>Timeline</li>
            <li style={{color: `${colors ? colors.seventh : ""}`}} className="footer-text" onClick={() => nav("projects")}>Projects</li>
            <li style={{color: `${colors ? colors.seventh : ""}`}} className="footer-text" onClick={() => nav("contact")}>Contact</li>
          </ul>
        </section>
        <section className="footer-section">
          <ul>
            <li style={{color: `${colors ? colors.seventh : ""}`}}>Sydney Curtis</li>
            <li style={{color: `${colors ? colors.seventh : ""}`}}><Link className="footer-text" to="https://www.linkedin.com/in/sydneycurtis012/">LinkedIn</Link></li>
            <li style={{color: `${colors ? colors.seventh : ""}`}}><Link className="footer-text" to="https://conveyeditingservices.com">CONVEY Editing and Curriculum Design</Link></li>
            <li style={{color: `${colors ? colors.seventh : ""}`}}><Link className="footer-text" to="mailto:syd.curtis012@gmail.com">syd.curtis012@gmail.com</Link></li>
          </ul>
        </section>
      </article>
    </footer>
  );
}