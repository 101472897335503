import React, {useEffect, useContext} from "react";
import { SiteContext } from '../../SiteContent';
import Project from '../Project';
import Navbar from '../Navbar';
import Footer from '../Footer';

const Projects = () => {
  const {
        fetchData,  
        setColors, 
        colors,
        getProjects, 
        setProjects, 
        projects
    } = useContext(SiteContext)
  useEffect(function(){
    if(projects){
        return;
    }else{
        const check = JSON.parse(sessionStorage.getItem('projects'));
        if(check){
            setProjects(check);
        }else{
            getProjects();
        }
    }
  }, [projects, setProjects, getProjects])
  useEffect(function(){
    if(colors){
        return;
    }else{
        const check = JSON.parse(sessionStorage.getItem('site_colors'));
        if(check){setColors(check);}
        else{fetchData();}
    }
  }, [colors, setColors, fetchData])
  return (
    <>
    <Navbar type={"normal"} />
      <div className='project-container-main'>
        <h1>Projects</h1>
        <input type="search" className='project-search' placeholder='Search Projects...' spellCheck='false'/>
      </div>
      <div className='projects-container'>
          {projects ? (
              projects.map((project, index) => (
                <Project key={index} project={project}/>
              ))
          ) : (
              <p>No projects available</p>
          )}
      </div>
      <Footer type={"normal"}/>
    </>
    );
}

export default Projects