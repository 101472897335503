import React, {useEffect, useContext} from "react";
import Navbar from '../Navbar';
import Footer from '../Footer';
import { useNavigate } from "react-router";
import { SiteContext } from '../../SiteContent';
import GradientColorPicker from '../GradientColorPicker';
import { HashLink as Link } from 'react-router-hash-link';

export default function AdminHome(){
    const {fetchData, setColors, colors, getUser, handleSiteGradients, refreshFlag} = useContext(SiteContext)
    useEffect(function(){
        getUser();
    }, [])
    useEffect(function(){
        const check = JSON.parse(sessionStorage.getItem('site_colors'));
        if(check){setColors(check);}
        else{fetchData();}
    }, [refreshFlag])
    const navigate = useNavigate();
    return (
        <>
            <Navbar type={"admin"} />
            {colors ? (
                <>
                    <article>
                    <section className='home-section'>
                        <GradientColorPicker 
                            nameOfField={"leftIntrog"} 
                            vert={true} 
                            imgCheck={true} 
                            imgName={'homeImg3'}
                        />
                        <div className='tall-home-item' id="first-tall-home-item" style={{backgroundImage: `linear-gradient(180deg, ${colors["leftIntrog"][0]} 27%, ${colors["leftIntrog"][1]} 52%, ${colors["leftIntrog"][2]} 77.5%, ${colors["leftIntrog"][3]} 100%), url(${colors.homeImg3[0]})`}}>
                        <div>
                            <h1>
                                <textarea 
                                    cols="11"
                                    rows="1"
                                    style={{color: colors.seventh}}
                                    value={colors.leftIntroText[0]}
                                    onChange={(e) => handleSiteGradients("leftIntroText", 0, e.target.value, e.target.value)}
                                />
                            </h1>
                            <h3>
                                <textarea 
                                    cols="11"
                                    rows="1"
                                    style={{color: colors.seventh}}
                                    value={colors.leftIntroText[1]}
                                    onChange={(e) => handleSiteGradients("leftIntroText", 1, e.target.value, e.target.value)}
                                />
                            </h3>
                            <p>
                                <textarea 
                                    cols="18" 
                                    rows="10"
                                    style={{color: colors.seventh}}
                                    value={colors.leftIntroText[2]}
                                    onChange={(e) => handleSiteGradients("leftIntroText", 2, e.target.value, e.target.value)}
                                />
                            </p>
                        </div>
                        </div>
                        <div className='multi-home-item'>
                        <GradientColorPicker 
                            nameOfField={"researchG"} 
                            vert={false} 
                            imgCheck={true}
                            imgName={'homeImg1'}
                        />  
                        <div className='long-home-item' style={{backgroundImage: `linear-gradient(99deg, ${colors["researchG"][0]} 0%, ${colors["researchG"][1]} 14.37%, ${colors["researchG"][2]} 48.18%), url(${colors["homeImg1"][0]}`}}>
                            <div className='top-content'>
                            <div className='left-content'>
                                <h1>
                                    <textarea 
                                        cols="45" 
                                        rows="1"
                                        style={{color: colors.seventh}}
                                        value={colors.OnGoingText[0]}
                                        onChange={(e) => handleSiteGradients("OnGoingText", 0, e.target.value, e.target.value)}
                                    />
                                </h1>
                                <p>
                                    <textarea 
                                        cols="60" 
                                        rows="4"
                                        style={{color: colors.seventh}}
                                        value={colors.OnGoingText[1]}
                                        onChange={(e) => handleSiteGradients("OnGoingText", 1, e.target.value, e.target.value)}
                                    />
                                </p>
                            </div>
                            <div className='bottom-content'>
                                <span className='caption'></span>
                                <button style={{backgroundColor:`${colors.third}`, color: colors.seventh }} onClick={()=>navigate("/projects")}>{colors.OnGoingText[2]}
                                </button>
                            </div>
                            </div>
                        </div>
                        <GradientColorPicker 
                            nameOfField={"publicationG"} 
                            vert={false} 
                            imgCheck={true}
                            imgName={'homeImg2'}
                        />  
                        <div className='long-home-item' style={{backgroundImage: `linear-gradient(99deg, ${colors["publicationG"][0]} 0%, ${colors["publicationG"][1]} 14.37%, ${colors["publicationG"][2]} 48.18%), url(${colors.homeImg2[0]})`}}>
                            <div className='top-content'>
                            <div className='left-content'>
                                <h1>
                                    <textarea 
                                        cols="45"
                                        rows="1"
                                        style={{color: colors.seventh}}
                                        value={colors.RecentPubText[0]}
                                        onChange={(e) => handleSiteGradients("RecentPubText", 0, e.target.value, e.target.value)}
                                    />
                                </h1>
                                <p>
                                    <textarea 
                                        cols="60"
                                        rows="4"
                                        style={{color: colors.seventh}}
                                        value={colors.RecentPubText[1]}
                                        onChange={(e) => handleSiteGradients("RecentPubText", 1, e.target.value, e.target.value)}
                                    />
                                </p>
                            </div>
                            <div className='bottom-content'>
                                <span className='caption'>
                                    <textarea 
                                        cols="30"
                                        rows="1"
                                        style={{color: colors.seventh}}
                                        value={colors.RecentPubText[2]}
                                        onChange={(e) => handleSiteGradients("RecentPubText", 2, e.target.value, e.target.value)}
                                    />
                                </span>
                                <button style={{backgroundColor:`${colors.fourth}`, color: colors.seventh}} onClick={()=>navigate("/projects")}>{colors.RecentPubText[3]} </button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    {/* POI */}
                    <section className='home-section'>
                        <div className='multi-home-item second-multi-home-item'>
                        <GradientColorPicker nameOfField={"aboutMeG"} vert={false}/>                     
                        <div className='long-home-item about-home-item' style={{backgroundImage: `linear-gradient(to bottom right, ${colors["aboutMeG"][0]}, ${colors["aboutMeG"][1]})`}}>
                            <h1>
                                <textarea 
                                    cols="45"
                                    rows="1"
                                    style={{color: colors.seventh}}
                                    value={colors.AboutMeText[0]}
                                    onChange={(e) => handleSiteGradients("AboutMeText", 0, e.target.value, e.target.value)}
                                />
                            </h1>
                            <div className='text-container'>
                            <p>
                                <textarea 
                                    cols="85" 
                                    rows="10"
                                    style={{color: colors.seventh}}
                                    value={colors.AboutMeText[1]}
                                    onChange={(e) => handleSiteGradients("AboutMeText", 1, e.target.value, e.target.value)}
                                />
                            </p>
                            </div>
                            <h1 className='text-spacer'>_</h1>
                        </div>
                        <GradientColorPicker nameOfField={"linkG"} vert={false} /> 
                        <div className='long-home-item useful-home-item' style={{backgroundImage: `linear-gradient(276deg, ${colors["linkG"][0]} 0%, ${colors["linkG"][1]} 100%)`}}>
                            <h1 className='useful-title' style={{color: colors.seventh}}>Useful Links</h1>
                            <div className='button-container'>
                            <button>
                                <Link 
                                    to={`/admin/projects#resume`} 
                                    scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                                    Resume
                                </Link>
                            </button>
                            <button>
                                <Link 
                                    to="https://conveyeditingservices.com"
                                    scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                                    Consulting Services
                                </Link>
                            </button>
                            <button>
                                <Link 
                                    to={`/admin/contact`} 
                                    scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                                    Contact Me
                                </Link>
                            </button>
                            </div>
                        </div>
                        </div>
                        <GradientColorPicker 
                            nameOfField={"sydneyG"} 
                            vert={true} 
                            imgCheck={true}
                            imgName={'homeImg4'}
                        /> 
                        <div className='tall-home-item' style={{backgroundImage: `url(${colors.homeImg4[0]}), linear-gradient(166deg, ${colors["sydneyG"][0]} 0%, ${colors["sydneyG"][1]} 100%)`, backgroundSize: `75%, 100%`, backgroundPosition: `bottom right, center`}}>
                        <h1 style={{color: colors.seventh}}>Sydney Curtis, Ph.D.</h1>
                        </div>
                    </section>
                </article>
                </>
            ) : (
                <h1>Site is loading</h1>
            )}
            <Footer type={"admin"}/>
        </>
    )
}