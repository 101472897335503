import React, {useContext, useState} from "react";
import {SiteContext} from "../SiteContent";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";

export default function ColorPicker(){
    const [display, setDisplay] = useState(false);
    const [urlVal, setUrlVal] = useState("");
    const {
        colors, 
        handleSiteColors, 
        handleProductionColors, 
        resetColors, 
        handleNewUrl
    } = useContext(SiteContext);
    return(
        <div >
            {display ? (
                <div className="colorPicker">
                    <input
                        type="color"
                        name="first"
                        id="first"
                        value={colors ? colors.first : ""}
                        onChange={(e) => handleSiteColors({ first: e.target.value })}
                    />
                    <input
                        type="color"
                        name="second"
                        id="second"
                        value={colors ? colors.second : ""}
                        onChange={(e) => handleSiteColors({ second: e.target.value })}
                    />
                    <input
                        type="color"
                        name="third"
                        id="third"
                        value={colors ? colors.third : ""}
                        onChange={(e) => handleSiteColors({ third: e.target.value })}
                    />
                    <input
                        type="color"
                        name="fourth"
                        id="fourth"
                        value={colors ? colors.fourth : ""}
                        onChange={(e) => handleSiteColors({ fourth: e.target.value })}
                    />
                    <input
                        type="color"
                        name="fifth"
                        id="fifth"
                        value={colors ? colors.fifth : ""}
                        onChange={(e) => handleSiteColors({ fifth: e.target.value })}
                    />
                    <input
                        type="color"
                        name="sixth"
                        id="sixth"
                        value={colors ? colors.sixth : ""}
                        onChange={(e) => handleSiteColors({ sixth: e.target.value })}
                    />
                    <input
                        type="color"
                        name="seventh"
                        id="seventh"
                        value={colors ? colors.seventh : ""}
                        onChange={(e) => handleSiteColors({ seventh: e.target.value })}
                    />
                    <input 
                        type="text" 
                        name="font" 
                        id="font"
                        value={urlVal}
                        onChange={(e) => setUrlVal(e.target.value)}
                        placeholder="Enter your font link"
                    />
                    <button onClick={() => handleNewUrl(urlVal)} className="color-button">Test new font</button>
                    <button onClick={()=>handleProductionColors()} className="color-button">Push Changes</button>
                    <button onClick={()=>resetColors()} className="color-button bottomButton">Reset Changes</button>
                    <button onClick={() => setDisplay((prev) => !prev)} className="nav-minus "><FaMinusCircle size="60px"/>
                    </button>
                </div>
            ): (
                <button onClick={() => setDisplay((prev) => !prev)} className="nav-plus"><FaPlusCircle /></button>
            )}   
        </div>
    )

}