import React, {useState} from "react";
import PDF from "./PDF";
import { useContext } from "react";
import { SiteContext } from "../SiteContent";
import { HashLink as Link } from 'react-router-hash-link';

export default function Project({project}){
    const {colors} = useContext(SiteContext)
    const [display, setDisplay] = useState(true);
    return (
        <div className={display ? "card-box" : "pdf-container"} id={project.title}>
            {display ? 
                (
                <div onClick={()=>setDisplay((prev)=> !prev)} className="card" style={{background: `linear-gradient(150deg, ${colors["cardG"][0]} 0%, ${colors["cardG"][1]} 100%)`}}>
                        <div className="card-inner-content">
                            <img alt="Project thumbnail" src={project.url}/>
                            <div>
                                <h1>{project.title}</h1>
                                <p>{project.desc}</p>
                            </div>
                         {project.publisherLink.trim() !== "0" ? (
                                <Link 
                                    to={project.publisherLink.trim()}
                                >
                                    Original Publisher
                                </Link>
                            ) : null}
                    </div>
                </div>
                ) : 
                (<PDF project={project} setDisplay={setDisplay} style={{width: '100vw'}}/>)
            }
        </div>
    )
}