import { useContext, useEffect } from 'react';
import { SiteContext } from '../../SiteContent';
import Navbar from '../Navbar';
import Footer from '../Footer';

function Contact(){
  const {fetchData, setColors, colors} = useContext(SiteContext)
  useEffect(function(){
    if(colors){
        return;
    }else{
        const check = JSON.parse(sessionStorage.getItem('site_colors'));
        if(check){setColors(check);}
        else{fetchData();}
    }
  }, [colors, setColors, fetchData])
  return(
    <>
    <Navbar type={"normal"} />
    {/* <div style={{height: "80vh", width: "100vw"}}> */}
      {/* <InlineWidget url="https://calendly.com/syd-curtis012" styles={{ height: '80vh', marginTop: 'auto', marginBottom: 'auto'}}/> */}
      <iframe className='candedly' width="100%" height="100%" frameborder="0" title="Calendly Scheduling Page" src="https://calendly.com/syd-curtis012?embed_type=Inline&amp;embed_domain=1"></iframe>
    {/* </div> */}
      <Footer type={"normal"}/>
    </>
  )
}

export default Contact