import React, {useState, useRef, useContext, useEffect} from "react";
import { SiteContext } from '../../SiteContent';
import { useNavigate } from "react-router";

export default function AdminLogin(){
    const imgRef = useRef(null);
    const [qrUrl, setQr] = useState("")
    const [display, setDisplay] = useState(true);
    const [form, setForm] = useState({
        email: "",
        password: "",
        code: "",
    });
    const {fetchData, setColors, colors} = useContext(SiteContext)
    useEffect(function(){
        if(colors){
            return;
        }else{
            const check = JSON.parse(sessionStorage.getItem('site_colors'));
            if(check){setColors(check);}
            else{fetchData();}
        }
    }, [colors, setColors, fetchData])
    const navigate = useNavigate();
    function updateForm(value) {
        return setForm((prev) => {
            return { ...prev, ...value };
        }); 
    }
    async function handleSubmit(e){
        e.preventDefault();
        const login_request = await fetch("https://sydneycurtisphd.site/login", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(form)
        });
        const login_response = await login_request.json();
        if(login_response){
            sessionStorage.removeItem('jwt_token');
            sessionStorage.setItem("jwt_token", login_response.token);
            setDisplay(false);
            const qrCode = await fetch("https://sydneycurtisphd.site/login2", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({...form, token: login_response.token})
            })
            const qr = await qrCode.json();
            if(qr.success){
                setQr(qr.dataUrl);
            }else{
                alert(qr.error);
            }
        }else{
            alert("Login failed");
        }
    }
    async function handleSubmit2(e){
        e.preventDefault();
        const token = sessionStorage.getItem("jwt_token");
        const login_request3 = await fetch("https://sydneycurtisphd.site/login3", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({...form, token})
        });
        const login_response3 = await login_request3.json();
        if(login_response3.success){
            navigate("/admin/home")
        }else{
            alert("Login failed");
        }
    }
    return (
        <div className="admin-login-form-container">
            {display ? (<form  className="admin-login-form" onSubmit={handleSubmit}>
                <h1>Admin Login</h1>
                <input 
                    type="text"
                    required
                    name="email"
                    id="email"
                    placeholder="Enter your email address"
                    onChange={(e) => updateForm({ email: e.target.value })}
                />
                <input 
                    type="password"
                    required
                    name="password"
                    id="password"
                    placeholder="Enter your password"
                    onChange={(e) => updateForm({ password: e.target.value })}
                />
                <button type="submit">Login</button>
            </form>) : (
                <form onSubmit={handleSubmit2} className="qr-form">
                    <img title="qrCode" src={qrUrl} alt="Profile" ref={imgRef} id="qrCode"/>
                    <input 
                        type="text" 
                        name="code" 
                        id="code" 
                        required 
                        placeholder="Enter you code"
                        value={form.code}
                        onChange={(e) => updateForm({ code: e.target.value })}
                    />
                    <button type="submit">Submit</button>
                </form>
            )}
        </div>
    )
}