import React, {useRef, useEffect} from "react";

function PDF({project, setDisplay}){
    const iframeRef = useRef(null);

    useEffect(() => {
        async function getBinary(){
            const binary_fetch = await fetch(`https://sydneycurtisphd.site/projects/pdf/${project.pdf_name}`, {
                method: "GET",
                headers: { "Content-Type": "application/json"},
            })
            const binary_response = await binary_fetch.json();
            if(binary_response.success){
                const reader = new FileReader();

                reader.onload = function(event) {
                    const base64String = event.target.result;
                    iframeRef.current.src = base64String;
                };

                const blob = new Blob([new Uint8Array(binary_response.file.buffer.data)], { type: 'application/pdf' });
                reader.readAsDataURL(blob);
            }
        }
        getBinary();
    }, [project.pdf_name]);

    return (
        <div>
            <iframe 
                    ref={iframeRef}
                    width="90%"
                    height="700px"
                    style={{marginLeft: "auto", marginRight: "auto"}}
                    title="test_iframe"
            />

            <button onClick={() => setDisplay((prev) => !prev)}>Close PDF</button>
        </div>
    );
}

export default PDF
