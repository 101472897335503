import React, {useEffect, useContext} from "react";
import { SiteContext } from '../../SiteContent'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Project from "../Project";
import GradientColorPicker from "../GradientColorPicker";
import Form from "../Form";
import DeleteButton from "../DeleteButton";

export default function AdminProjects(){
    const {
        fetchData,
        setColors, 
        colors, 
        getUser, 
        getProjects, 
        setProjects, 
        projects
    } = useContext(SiteContext)
    useEffect(function(){
        getUser();
    }, [])
    useEffect(function(){
        if(colors){
            return;
        }else{
            const check = JSON.parse(sessionStorage.getItem('site_colors'));
            if(check){setColors(check);}
            else{fetchData();}
        }
    }, [])
    useEffect(function(){
        if(projects){
            return;
        }else{
            const check = JSON.parse(sessionStorage.getItem('projects'));
            if(check){
                setProjects(check);
            }else{
                getProjects();
            }
        }
    }, [projects])
    return (
        <>
            <Navbar type={"admin"} />
                <div className='project-container-main'>
                    <h1>Projects</h1>
                    <input type="search" className='project-search' placeholder='Search Projects...' spellCheck='false'/>
                    <Form/>
                    <GradientColorPicker nameOfField={"cardG"} />
                </div>
                <div className='projects-container'>
                    {projects ? (
                        projects.map((project, index) => (
                            <div className="admin-project-container">
                                <Project key={index} project={project}/>
                                <DeleteButton project={project}/>
                            </div>
                        ))
                    ) : (
                        <p>No projects available</p>
                    )}
                </div>
            <Footer type={"admin"}/>
        </>
    )
}