import React, {useEffect, useContext, useState} from "react";
import { SiteContext } from '../../SiteContent'
import Navbar from '../Navbar';
import Footer from '../Footer';
import TimelineImg from '../../Images/line.png';
import Left from '../../Images/left.svg'
import Right from '../../Images/right.svg';
import DashedLine from '../../Images/dashedLine.png'
import GoTo from '../../Images/go-to.svg';
import { HashLink as Link } from 'react-router-hash-link';
import GradientColorPicker from "../GradientColorPicker";

export default function AdminTimeline(){
    const [scroll, setScroll] = useState(0);
    const [timelineWidth, setTimelineWidth] = useState(100)
    const {
        fetchData, 
        setColors, 
        colors, 
        getUser, 
        getProjects, 
        setProjects, 
        projects
    } = useContext(SiteContext)
    console.log(colors);
    useEffect(function(){
        getUser();
    }, [])
    useEffect(function(){
        if(colors){
            return;
        }else{
            const check = JSON.parse(sessionStorage.getItem('site_colors'));
            if(check){setColors(check);}
            else{fetchData();}
        }
    }, [])
    useEffect(function(){
        if(projects){
            let length = Math.ceil(projects.length / 3) * 100
            setTimelineWidth(length)
            return;
        }else{
            const check = JSON.parse(sessionStorage.getItem('projects'));
            if(check){
                setProjects(check);
                let length = Math.ceil(check.length / 3) * 100
                setTimelineWidth(length)
            }else{
                getProjects();
            }
        }
    }, []);
    function changeScroll(dir){
        if(dir === 'right'){
            if(Math.abs(scroll) !== timelineWidth - 100){
                setScroll(scroll - 100)
            }
        }else{
            if(scroll !== 0){
                setScroll(scroll + 100)
            }
        }
    }
    return(
        <>
            <Navbar type={"admin"} />
            <div className="gradient-container">
                <GradientColorPicker 
                    nameOfField={"timeLineG"}
                />
            </div>
            
            <section className="timeline-section">
                {/* <img src={TimelineImg} alt="" /> */}
                <div className="timeline" style={{backgroundImage: `url(${TimelineImg})`, transform: `translateX(${scroll}vw)`, width: `${timelineWidth}vw`, filter: 'drop-shadow(0 0 35px #ff3c6900)'}}>
                    {projects ? (
                    projects
                        .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort projects by date
                        .map((project, index) => (
                        <div key={project.id} className="timeline-item" id={`timeline-${index+1 > 6 ? index+1 - (6*(Math.floor(index/6))): index+1}`} style={{width: `${100/3}vw`}}>
                            <img src={DashedLine} alt="" className='dashed-line'/>
                            <div className='outer-circle'>
                                <div className='inner-circle'></div>
                            </div>
                            <h3>{project.date.split('T')[0]}</h3>
                            <div className="project-card" style={{backgroundImage: `linear-gradient(99deg, ${colors["timeLineG"][0]} 0%, ${colors["timeLineG"][1]} 35.17%, ${colors["timeLineG"][2]} 62.22%), url(${project.url})`}}>
                                <h2>{project.title}</h2>
                                <button>
                                    <Link 
                                        to={`/admin/projects#${project.title}`} 
                                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                                        <img src={GoTo} alt=">"/>
                                    </Link>
                                </button>
                            </div>
                        </div>
                        ))
                    ) : (
                    <div className="timeline-item">
                        <p>No projects available</p>
                    </div>
                    )}
                </div>
                <section className="mobile-large-timeline">
                <div className="line"></div>
                {projects ? (
                projects
                    .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort projects by date
                    .map((project, index) => (
                    <div key={project.id} className="timeline-item" id={`timeline-${Math.round(index % 2)}`}>
                        <img src={DashedLine} alt="" className='dashed-line'/>
                        <div className="date-project-container">
                            <h3>{project.date.split('T')[0]}</h3>
                            <div className="project-card" style={{backgroundImage: `linear-gradient(99deg, ${colors["timeLineG"][0]} 0%, ${colors["timeLineG"][1]} 35.17%, ${colors["timeLineG"][2]} 62.22%), url(${project.url})`}}>
                                <h2>{project.title}</h2>
                                <button>
                                    <Link 
                                        to={`/admin/projects#${project.title}`} 
                                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                                        <img src={GoTo} alt=">"/>
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                    ))
                ) : (
                <div className="timeline-item">
                    <p>No projects available</p>
                </div>
                )}
            </section>
            <section className="mobile-small-timeline">
                <div className="line"></div>
                {projects ? (
                projects
                    .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort projects by date
                    .map((project, index) => (
                    <div key={project.id} className="timeline-item">
                        <img src={DashedLine} alt="" className='dashed-line'/>
                        <div className="date-project-container">
                            <h3>{project.date.split('T')[0]}</h3>
                            <div className="project-card" style={{background: `linear-gradient(99deg, #f3883d 0%, rgba(243, 136, 61, 0.92) 35.17%, rgba(243, 136, 61, 0.26) 62.22%), url(${project.url})`}}>
                                <h2>{project.title}</h2>
                                <button>
                                    <Link 
                                        to={`/admin/projects#${project.title}`} 
                                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                                        <img src={GoTo} alt=">"/>
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                    ))
                ) : (
                <div className="timeline-item">
                    <p>No projects available</p>
                </div>
                )}
            </section>
                <div className="button-container">
                    <button onClick={() => changeScroll('left')}><img src={Left} alt="<"></img></button>
                    <button onClick={() => changeScroll('right')}><img src={Right} alt=">"></img></button>
                </div>
            </section>
            <Footer type={"admin"}/>
        </>
    )
}