import { SiteContext } from "../SiteContent";
import { useContext } from "react";

export default function DeleteButton({project}){
    const { getProjects } = useContext(SiteContext)
    async function deleteProject(){
        const delete_fetch = await fetch(`https://sydneycurtisphd.site/projects/remove/${project.pdf_name}`, {
            method: 'DELETE',
            headers: {"Content-Type": "application/json"}
        });
        const delete_response = await delete_fetch.json();
        if(delete_response.success){
            await getProjects();
        }else{
            alert(delete_response.error);
        }
    }
    return (<button onClick={async()=>await deleteProject()}>Delete Project</button>)
}