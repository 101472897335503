import Navbar from '../Navbar';
import Footer from '../Footer';
import { useNavigate } from "react-router";
import { useContext, useEffect } from 'react';
import { SiteContext } from '../../SiteContent';
import { HashLink as Link } from 'react-router-hash-link';
import LoadingIcons from 'react-loading-icons'

export default function Home(){
  const navigate = useNavigate();
  const {fetchData, setColors, colors} = useContext(SiteContext)
  useEffect(function(){
    if(colors){
        return;
    }else{
        const check = JSON.parse(sessionStorage.getItem('site_colors'));
        if(check){setColors(check);}
        else{fetchData();}
    }
  }, [colors, setColors, fetchData])
  return (
    <div className='main'>
    <Navbar type={"normal"} />
    {colors ? (<>
      <article>
        <section className='home-section'>
            <div className='tall-home-item' id="first-tall-home-item" style={{backgroundImage: `linear-gradient(180deg, ${colors["leftIntrog"][0]} 27%, ${colors["leftIntrog"][1]} 52%, ${colors["leftIntrog"][2]} 77.5%, ${colors["leftIntrog"][3]} 100%), url(${colors.homeImg3[0]})`}}>
              <div>
                <h1 style={{color: colors.seventh}}>{colors.leftIntroText[0]}</h1>
                <h3 style={{color: colors.seventh}}>{colors.leftIntroText[1]}</h3>
                <p style={{color: colors.seventh}}>{colors.leftIntroText[2]}</p>
              </div>
            </div>
            <div className='multi-home-item'>
              <div className='long-home-item' style={{backgroundImage: `linear-gradient(99deg, ${colors["researchG"][0]} 0%, ${colors["researchG"][1]} 14.37%, ${colors["researchG"][2]} 48.18%), url(${colors.homeImg1[0]})`}}>
                <div className='top-content'>
                  <div className='left-content'>
                    <h1 style={{color: colors.seventh}}>{colors.OnGoingText[0]}</h1>
                    <p style={{color: colors.seventh}}>{colors.OnGoingText[1]}</p>
                  </div>
                  <div className='bottom-content'>
                    <span className='caption'></span>
                    <button style={{backgroundColor:`${colors.third}`, color: colors.seventh}} onClick={()=>navigate("/projects")}>{colors.OnGoingText[2]}</button>
                  </div>
                </div>
              </div>
              <div className='long-home-item' style={{backgroundImage: `linear-gradient(99deg, ${colors["publicationG"][0]} 0%, ${colors["publicationG"][1]} 14.37%, ${colors["publicationG"][2]} 48.18%), url(${colors.homeImg2[0]})`}}>
                <div className='top-content'>
                  <div className='left-content'>
                    <h1 style={{color: colors.seventh}}>{colors.RecentPubText[0]}</h1>
                    <p style={{color: colors.seventh}}>{colors.RecentPubText[1]}</p>
                  </div>
                  <div className='bottom-content'>
                    <span className='caption'>{colors.RecentPubText[2]}</span>
                    <button style={{backgroundColor:`${colors.fourth}`, color: colors.seventh}} onClick={()=>navigate("/projects")}>{colors.RecentPubText[3]}</button>
                  </div>
                </div>
              </div>
            </div>
        </section>
        {/* POI */}
        <section className='home-section'>
            <div className='multi-home-item second-multi-home-item'>
              <div className='long-home-item about-home-item' style={{backgroundImage: `linear-gradient(to bottom right, ${colors["aboutMeG"][0]}, ${colors["aboutMeG"][1]})`}}>
                <h1 style={{color: colors.seventh}}>{colors.AboutMeText[0]}</h1>
                <div className='text-container'>
                  <p style={{color: colors.seventh}}>{colors.AboutMeText[1]}</p>
                </div>
                <h1 className='text-spacer'>_</h1>
              </div>
              <div className='long-home-item useful-home-item' style={{backgroundImage: `linear-gradient(276deg, ${colors["linkG"][0]} 0%, ${colors["linkG"][1]} 100%)`}}>
                <h1 className='useful-title' style={{color: colors.seventh}}>Useful Links</h1>
                <div className='button-container'>
                  <button>
                    <Link 
                        to={`/projects#resume`} 
                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                        Resume
                    </Link>
                  </button>
                  <button>
                    <Link 
                        to="https://conveyeditingservices.com"
                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                        Consulting Services
                    </Link>
                  </button>
                  <button>
                    <Link 
                        to={`/contact`} 
                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                        Contact Me
                    </Link>
                  </button>
                </div>
              </div>
            </div>
            <div className='tall-home-item' style={{backgroundImage: `url(${colors.homeImg4[0]}), linear-gradient(166deg, ${colors["sydneyG"][0]} 0%, ${colors["sydneyG"][1]} 100%)`, backgroundSize: `75%, 100%`, backgroundPosition: `bottom right, center`}}>
              <h1 style={{color: colors.seventh}}>Sydney Curtis, Ph.D.</h1>
            </div>
        </section>
      </article>
      </>): (
                <h1 className='main-loading'><LoadingIcons.Oval /></h1>
            )}
      
      <Footer type={"normal"}/>
    </div>
  )
}