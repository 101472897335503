import React, {useState} from 'react'
import { useNavigate } from "react-router";

const SiteContext = React.createContext();

function SiteContent({children}){
    const navigate = useNavigate();
    const [colors, setColors] = useState(null);
    const [refreshFlag, setRefreshFlag] = useState(true);
    const [projects, setProjects] = useState(null);

    async function fetchData(){
        const siteColorsFetch = await fetch("https://sydneycurtisphd.site/site/info", {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });
        const siteColors = await siteColorsFetch.json();
        if (siteColors.success){
            sessionStorage.removeItem('site_colors');
            sessionStorage.setItem('site_colors', JSON.stringify(siteColors.site[0]));
            setColors(siteColors.site[0]);
            console.log(siteColors.site[0]);
            setRefreshFlag(false);
            const styleTags = document.querySelectorAll('style[data-font-style]');
            styleTags.forEach(tag => {
                if (tag.parentNode === document.head) {
                    tag.parentNode.removeChild(tag);
                } else {
                    console.warn('Style tag parent node is not document.head:', tag.parentNode);
                }
            });
            const response = await fetch(siteColors.site[0].fontUrl);
            const cssText = await response.text();
            const style = document.createElement('style');
            style.textContent = cssText;
            document.head.appendChild(style);
            console.log(style)
        }else{
            alert(siteColors.error);
        }
    };
    function handleSiteColors(value){
        return setColors((prev) => {return {...prev, ...value}})
    }
    function handleSiteGradients(field, index, value){
        var arr = colors[field].map((x, i) => i===index ? value : x);
        setColors((prev) => {return {...prev,  [field]: arr}});
    }
    async function resetColors() {
        const oldColors = JSON.parse(sessionStorage.getItem('site_colors'));

        if (!oldColors) {
            return; // Exit early if oldColors is not available
        }

        if (oldColors.fontUrl !== colors.fontUrl) {
            setColors(oldColors);

            // Remove existing style tags with the data-font-style attribute
            const styleTags = document.querySelectorAll('style[data-font-style]');
            styleTags.forEach(tag => {
                if (tag.parentNode === document.head) {
                    tag.parentNode.removeChild(tag);
                } else {
                    console.warn('Style tag parent node is not document.head:', tag.parentNode);
                }
            });

            try {
                // Fetch the CSS text for the old font URL
                const response = await fetch(oldColors.fontUrl);
                const cssText = await response.text();

                // Create a new style element and append it to the document head
                const style = document.createElement('style');
                style.setAttribute('data-font-style', ''); // Add data attribute
                style.textContent = cssText;
                document.head.appendChild(style);
            } catch (error) {
                console.error('Error fetching or applying font CSS:', error);
            }
        } else {
            setColors(oldColors);
        }
    }

    async function handleProductionColors(){
        const formData = new FormData();
        var updatedColors = { ...colors };
        var updatedImages = [];
        ["homeImg1", "homeImg2", "homeImg3", "homeImg4"].map(field => {
            if(colors[field][1] !== ""){
                formData.append("files", colors[field][1]);
                updatedImages.push(field);
                delete updatedColors[field];
                return field;
            }else{
                delete updatedColors[field];
                return field;
            }
        })
        const oldColors = JSON.parse(sessionStorage.getItem('site_colors'));
        delete updatedColors["_id"];
        delete updatedColors["__v"];
        const newKeys = Object.keys(updatedColors);
        newKeys.forEach(key => {
            if(typeof updatedColors[key] === "string"){
                if(updatedColors[key] === oldColors[key]){
                    delete updatedColors[key];
                }
            }else{
                if(updatedColors[key].every((value, index) => value === oldColors[key][index])){
                    delete updatedColors[key];
                }
            }
        })
        formData.append("updatedImages", JSON.stringify(updatedImages));
        formData.append("value", JSON.stringify(updatedColors));
        const productionColors = await fetch("https://sydneycurtisphd.site/site/edit", {
            method: 'PUT',
            body: formData,
        })
        const result = await productionColors.json();
        console.log(result);
        if(result.success){
            alert("Success")
            await fetchData();
        }else{
            alert(result.error);
        }
    }
    async function getUser(){
        const token = sessionStorage.getItem('jwt_token');
        if(token){
            const user = await fetch("https://sydneycurtisphd.site/account/user", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({token})
            })
            const user_response = await user.json();
            if(!user_response.success){
                navigate("/home")
            }
            return "success";
        }else{
            navigate("/home")
        }
    }
    async function handleFrontPageImages(field, file) {
        const reader = new FileReader();

        reader.onloadend = () => {
            setColors(prev => ({ ...prev, [field]: [reader.result, file]}));
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    }
    async function getProjects(){
        sessionStorage.removeItem('projects');
        const projects_fetch = await fetch("https://sydneycurtisphd.site/projects/get-projects", {
            method: "GET",
            headers: { "Content-Type": "application/json"},
        })
        const projects_response = await projects_fetch.json();
        if(projects_response.success){
            sessionStorage.setItem('projects', JSON.stringify(projects_response.projects));
            setProjects(projects_response.projects);
        }else{
            alert(projects_response.error);
        }
    }
    async function handleNewUrl(url){
        try{
            setColors((prev) => { return {...prev, fontUrl: url}; });
            const styleTags = document.querySelectorAll('style[data-font-style]');
            styleTags.forEach(tag => {
                if (tag.parentNode === document.head) {
                    tag.parentNode.removeChild(tag);
                } else {
                    console.warn('Style tag parent node is not document.head:', tag.parentNode);
                }
            });
            const response = await fetch(url);
            const cssText = await response.text();
            const style = document.createElement('style');
            style.textContent = cssText;
            document.head.appendChild(style);
            console.log(style)
        }catch(e){
            alert("Invalid url");
        }
    }
    return (
        <SiteContext.Provider value={
            {
                colors, 
                handleSiteColors, 
                handleProductionColors, 
                resetColors, 
                fetchData, 
                refreshFlag, 
                setRefreshFlag, 
                setColors, 
                getUser, 
                getProjects, 
                projects, 
                setProjects, 
                handleSiteGradients,
                handleFrontPageImages,
                handleNewUrl
            }
        }>
            {colors ? (
                    <>
                        <div style={{ fontFamily: colors.fontUrl ? `${colors.fontUrl.match(/\?family=([^:&]+)/)[1].replace('+', ' ')}` : '' }}>
                        {children}
                        </div>
                        {colors.fontUrl && <style data-font-style>{`/* Empty style tag to apply the font dynamically */`}</style>}
                        {colors.fontUrl && <link href={colors.fontUrl} rel="stylesheet" />}
                    </>
                    ) : (
                    <>{children}</> // Wrapping children in a fragment
                    )}   
            
        </SiteContext.Provider>
    )
}

export {SiteContext, SiteContent}