import React, { useState, useEffect } from 'react';
import { useContext } from "react";
import { SiteContext } from "../SiteContent";


function RgbaColorPicker({ initialColor, name, index }) {
  const {handleSiteGradients} = useContext(SiteContext);
  const [red, setRed] = useState(0);
  const [green, setGreen] = useState(0);
  const [blue, setBlue] = useState(0);
  const [alpha, setAlpha] = useState(1);

  useEffect(() => {
    if (initialColor) {
      const rgbaValues = initialColor.match(/\d+(\.\d+)?/g).map(Number);
      setRed(rgbaValues[0]);
      setGreen(rgbaValues[1]);
      setBlue(rgbaValues[2]);
      setAlpha(rgbaValues[3]);
    }
  }, [initialColor]);

  const handleColorChange = (color, value) => {
    value = Number(value);
    switch (color) {
      case 'red':
        setRed(value);
        break;
      case 'green':
        setGreen(value);
        break;
      case 'blue':
        setBlue(value);
        break;
      case 'alpha':
        setAlpha(value);
        break;
      default:
        break;
    }
    handleSiteGradients(name, index, `rgba(${red}, ${green}, ${blue}, ${alpha})`);
  };

  return (
    <div className="rgba-picker">
      <div className="color-box" style={{ backgroundColor: `rgba(${red}, ${green}, ${blue}, ${alpha})`, width: '100px', height: '100px' }}></div>
      <div className="rgba-fields">
        <div>
          <label>{red}(R): </label>
          <input type="range" min="0" max="255" step="1" value={red} onChange={(e) => handleColorChange('red', e.target.value)} />
        </div>
        <div>
          <label>{green}(G): </label>
          <input type="range" min="0" max="255" step="1" value={green} onChange={(e) => handleColorChange('green', e.target.value)} />
        </div>
        <div>
          <label>{blue}(B): </label>
          <input type="range" min="0" max="255" step="1" value={blue} onChange={(e) => handleColorChange('blue', e.target.value)} />
        </div>
        <div>
          <label>{alpha}(A): </label>
          <input type="range" min="0" max="1" step="0.01" value={alpha} onChange={(e) => handleColorChange('alpha', e.target.value)} />
        </div>
      </div>
    </div>
  );
}

export default RgbaColorPicker;
