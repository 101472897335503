import { useNavigate } from "react-router";
import logo from "../Images/logo_1.png";
import ColorPicker from "./ColorPicker";
import burgerMenu from '../Images/burger-menu.svg';
import {SiteContext} from "../SiteContent";
import { useContext } from "react";
import MobileNav from "./MobileNav";

export default function Navbar({type}) {
  const {colors} = useContext(SiteContext)
  var pathing;
  if(type === "normal") pathing = ""
  else pathing = "admin/"
  const navigate = useNavigate();
  const navEnum = {
    "home": () => navigate(`/${pathing}home`),
    "projects": () => navigate(`/${pathing}projects`),
    "contact": () => navigate(`/${pathing}contact`),
    "timeline": () => navigate(`/${pathing}timeline`)
  };

  function mobileView() {
    document.getElementById("mobile-nav").classList.toggle("mobile-active");
  }

  function nav(route) {
    navEnum[route]();
  }
  return colors ? (
    <nav style={{
      backgroundColor: `${colors ? colors.first : ""}`, 
      borderBottom: `1.8rem ${colors ? colors.second : ""} solid`
    }}>
      {
        type !== "normal" ? <ColorPicker /> : ""
      }
      <img src={logo} alt="Portfolio Logo"/>
      <div className="nav-btn-section">
        <button className="nav-btn" style={{color: `${colors ? colors.seventh : ""}`}} onClick={() => nav("home")}>Home</button>
        <button className="nav-btn" style={{color: `${colors ? colors.seventh : ""}`}} onClick={() => nav("timeline")}>Timeline</button>
        <button className="nav-btn" style={{color: `${colors ? colors.seventh : ""}`}} onClick={() => nav("projects")}>Projects</button>
        <button className="nav-btn" style={{color: `${colors ? colors.seventh : ""}`}} onClick={() => nav("contact")}>Contact</button>
      </div>
      <img src={burgerMenu} alt="burgerMenu" className="burger-icon" onClick={() => mobileView()}/>
      <MobileNav/>
    </nav>
  ) : (
    <div>Loading Site colors</div>
  )
}